// Footer.js

import React from "react";
import { ReactComponent as RFLogo } from "../Assets/logo_text_light.svg";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex z-30 relative items-center ipad:px-0 px-5 lg:mt-0 mt-0 flex-col w-full bg-dark border-t border-[#163155]">
        <div className="w-full max-w-5xl gap-10 text-white pt-10 lg:pt-16 pb-10 lg:grid lg:grid-cols-4">
          <div>
            <div className="flex w-full justify-center lg:justify-start items-center text-lg font-nunito font-bold">
              <div className="lg:h-7 h-6">
                <RFLogo />
              </div>
            </div>
            <p className="mt-6 lg:block hidden ">{t("footer.description")}</p>
          </div>

          <div className="hidden lg:flex justify-center">
            <div className="flex flex-col">
              <h1 className="text-xl font-semibold mb-5 text-white">
                {t("footer.pages")}
              </h1>
              <a className="text-base mb-2" href="/">
                {t("footer.home")}
              </a>
              <a className="text-base mb-2" href="/contact">
                {t("footer.requestDemo")}
              </a>
            </div>
          </div>

          <div className="flex text-center lg:text-left lg:mt-0 mt-6 justify-center">
            <div className="flex flex-col">
              <h1 className="text-xl lg:block hidden font-semibold mb-5 text-white">
                {t("footer.legal")}
              </h1>
              <a
                className="text-lg lg:text-base font-bold lg:font-medium lg:mt-0 mt-3 mb-2"
                href="/datapolicy"
              >
                {t("footer.privacyPolicy")}
              </a>
              <a
                className="text-lg lg:text-base font-bold lg:font-medium  lg:mt-0 mt-3 mb-2"
                href="/imprint"
              >
                {t("footer.imprint")}
              </a>
            </div>
          </div>

          <div className="flex flex-col items-center lg:items-end lg:mt-0 mt-4 ">
            <div className="flex flex-col lg:items-start items-center">
              <h1 className="text-xl lg:block hidden font-semibold mb-5 text-white">
                {t("footer.contact")}
              </h1>
              <div className="flex items-center ">
                <a
                  href="tel:+4915789277139"
                  className="text-lightgreen lg:text-white font-bold text-base"
                >
                  {t("footer.phone")}
                </a>
              </div>

              <div className="lg:flex hidden mt-4 lg:mt-2 items-center ">
                <a
                  href="mailto:hallo@mudioo.de"
                  className="text-primary lg:text-primary font-bold text-base"
                >
                  {t("footer.email")}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="text-lightgray text-base py-7 border-t border-[#031f4f] w-full max-w-5xl flex justify-center">
          {t("footer.copyright")}
        </div>
      </div>
    </>
  );
}

export default Footer;
