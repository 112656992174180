import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import AudioControls from "../Assets/AudioControls";

export default function AudioguideSample() {
  const { t } = useTranslation();
  const [trackProgress, setTrackProgress] = useState(0);

  const audioSrc = require("../Assets/audio_sample.mp3");
  const audioRef = useRef(new Audio(audioSrc));
  const { duration } = audioRef.current;
  const intervalRef = useRef();
  const isReady = useRef(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const currentPercentage = duration
    ? `${(trackProgress / duration) * 100}%`
    : "0%";
  const trackStyling = `
  -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${currentPercentage}, #fff), color-stop(${currentPercentage}, #777))
`;

  const startTimer = () => {
    clearInterval(intervalRef.current);

    intervalRef.current = setInterval(() => {
      if (audioRef.current.ended) {
        setIsPlaying(false);
        setTrackProgress(0);
      } else {
        setTrackProgress(audioRef.current.currentTime);
      }
    }, [1000]);
  };

  const onScrub = (value) => {
    clearInterval(intervalRef.current);
    audioRef.current.currentTime = value;
    setTrackProgress(audioRef.current.currentTime);
  };

  const onScrubEnd = () => {
    if (!isPlaying) {
      setIsPlaying(true);
    }
    startTimer();
  };

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play();
      startTimer();
    } else {
      audioRef.current.pause();
    }
  }, [isPlaying]);

  useEffect(() => {
    return () => {
      audioRef.current.pause();
      clearInterval(intervalRef.current);
    };
  }, []);

  return (
    <div className="bg-white to-white px-5 lg:px-10 lg:pb-16 pb-5 lg:pb-10 lg:pt-10 pt-0 w-full flex flex-col items-center">
      <div className="w-full flex flex-row max-w-6xl items-center relative">
        <div className="lg:w-6/12 lg:pr-14 lg:block flex flex-col items-center w-full">
          <h2 className="font-bold text-start pr-16 lg:pr-0 text-text w-full text-3xl mt-2 lg:mt-10 leading-[44px]">
            {t("audioguideTitle")}
          </h2>
          <p className="text-darkgray lg:flex text-center text-start text-lg mt-3 lg:mt-5">
            {t("audioguideDescription")}
          </p>

          <div className="lg:h-16 -ml-2 hidden lg:flex lg:w-64 cursor-pointer lg:px-2 px-4 border-border lg:border-white hover:border-border transition-all hover:bg-lightgray lg:bg-white bg-[#FCFBFF] lg:bg-none border rounded-lg mt-4 items-center">
            <AudioControls
              isPlaying={isPlaying}
              onPlayPauseClick={setIsPlaying}
            />
            <div className="ml-3 text-dark">
              <div className="font-semibold">{t("customerAdvisor")}</div>
              {isPlaying ? (
                <input
                  type="range"
                  value={trackProgress}
                  step="1"
                  min="0"
                  max={duration ? duration : `${duration}`}
                  className="w-full h-1 bg-darkgray rounded-lg appearance-none cursor-pointer"
                  onChange={(e) => onScrub(e.target.value)}
                  onMouseUp={onScrubEnd}
                  onKeyUp={onScrubEnd}
                  style={{ background: trackStyling }}
                />
              ) : (
                <div className="font-regular h-6 text-sm text-darkgray">
                  {t("playText")}
                </div>
              )}
            </div>
          </div>

          <div className="w-full lg:hidden rounded-lg flex flex-col items-center mt-8 lg:mt-4">
            <div className="w-full relative lg:h-40 h-72 rounded-lg overflow-hidden">
              <img
                alt="retailflow Logo"
                src={require("../Assets/bike.jpg")}
                className="h-full lg:-mt-6 -mt-10 rounded-lg"
              />
              <div className="absolute w-full h-full bg-gradient-to-r from-black opacity-60 to-black top-0 left-0"></div>
              <div className="absolute w-full lg:bg-none bottom-0 lg:px-0 lg:py-0 px-8 py-2 pt-5 lg:pt-0 lg:bottom-3 left-0 lg:left-6">
                <div className="flex lg:p-0 px-5 py-2 pb-4 lg:bg-none bg-white rounded-lg items-center">
                  <AudioControls
                    isPlaying={isPlaying}
                    onPlayPauseClick={setIsPlaying}
                  />
                  <div className="lg:ml-2 ml-4">
                    <div className="font-bold lg:text-base text-lg mt-2 text-dark lg:text-white">
                      {t("bikeName")}
                    </div>
                  </div>
                </div>
                {isPlaying ? (
                  <input
                    type="range"
                    value={trackProgress}
                    step="1"
                    min="0"
                    max={duration ? duration : `${duration}`}
                    className="w-full mt-4 h-1 bg-primary rounded-lg appearance-none cursor-pointer"
                    onChange={(e) => onScrub(e.target.value)}
                    onMouseUp={onScrubEnd}
                    onKeyUp={onScrubEnd}
                    style={{ background: trackStyling }}
                  />
                ) : (
                  <div className="h-6 mt-0.5"></div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="lg:w-6/12 lg:block hidden mt-10 pl-16">
          <div className="h-full relative w-auto rounded-lg shadow-[lightgray] shadow">
            <img
              alt="retailflow Logo"
              src={require("../Assets/bike.jpg")}
              className="lg:w-full w-auto h-full lg:h-auto rounded-lg"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
