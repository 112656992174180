import React from "react";
import Menubar from "../Components/Menubar";
import Pricing from "../Components/Pricing";
import Contact from "../Components/Contact";
import MoreInformation from "../Components/MoreInformation";
import FinancingOptions from "../Components/FinancingOptions";
import { InlineWidget } from "react-calendly";

function Pricingoverview() {
  return (
    <div>
      <Menubar selected="pricing" />
      <div className="flex pt-28 ipad:px-0 lg:mb-0 mb-10  px-0 justify-center w-full ">
        <div className="w-full bg-primary overflow-hidden  relative px-5  pt-20 pb-12 lg:py-20 flex-col items-center justify-center flex">
          <h2 className="lg:text-5xl pt-4  text-center text-2xl z-20 text-white font-bold lg:leading-[60px]">
            Einfach mal ausprobieren <br className="lg:block hidden" />
          </h2>
          <p className="text-base lg:text-lg lg:max-w-2xl relative text-lightgreen text-center mt-4  z-30">
            Die mudioo-Sodtware kann kostenlos und unverbindlich getestet
            werden. Vereinbaren Sie dazu einfach ein Infogespräch. Wir geben
            Ihnen einen kurzen Einblick in die Funktionen und
            Einsatzmöglichkeiten und schalten Ihnen gerne einen kostenlosen
            Testaccount frei.
          </p>
          <a
            target="_blank"
            href="https://calendly.com/christoph-v-trotha/-mudioo"
            className="bg-dark cursor-pointer font-bold h-14 z-20 w-4/5  lg:w-72 rounded-full lg:ml-3 lg:mt-10 mt-8 flex items-center justify-center text-white"
          >
            Infogespräch buchen
          </a>

          <div className="mt-5 lg:mt-8 w-full lg:w-2/3 lg:items-center">
            <div className="flex lg:flex-row mb-5 flex-col">
              <div className="bg-secondary absolute -top-[470px] lg:-top-96 z-10 -left-56 lg:-left-96 w-[600px] h-[600px] rounded-full" />
              <div className="bg-secondary absolute top-36 lg:top-56 -right-56 w-96 h-96 rounded-full" />
            </div>
          </div>
        </div>
      </div>
      <Pricing />

      <div className="w-full flex justify-center">
        <div className="w-full relative flex flex-col pt-10 lg:pt-28 lg:px-0 px-5 pb-12 max-w-5xl">
          <h2 className="text-3xl shrink-0 text-text lg:text-center font-bold ">
            Lust, retailflow kostenlos auszuprobieren?
          </h2>
          <p className="text-darkgray lg:text-lg relative  lg:text-center mt-4 mb-10 lg:-mb-6 z-30">
            Jetzt ein unverbindliches Infogespräch buchen.
            <br /> Wir schalten Ihnen gerne einen kostenlosen Testaccount frei.
          </p>
          <div className="border border-border lg:border-0">
            <InlineWidget url="https://calendly.com/christoph-v-trotha/-mudioo" />
          </div>
        </div>
      </div>

      <div className="lg:h-28" />
      {/* <MoreInformation /> */}
      <Contact type="Kontakt" bgmode={true} />
    </div>
  );
}

export default Pricingoverview;
