import { useState, useEffect } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import { useTranslation } from "react-i18next";
import { CheckCircleIcon } from "@heroicons/react/24/outline";

function Pricing() {
  //   const { scrollY } = useScroll();
  //   const translateXLeft = useTransform(scrollY, [1400, 1800], [40, 0]);
  //   const translateXRight = useTransform(scrollY, [1400, 1800], [-40, 0]);

  //   const translateYTop = useTransform(scrollY, [1400, 1800], [40, 0]);
  //   const translateYBottom = useTransform(scrollY, [1400, 1800], [-40, 0]);
  const [priceOption, setPriceOption] = useState(true);
  const [isVisible, setIsVisible] = useState(true);
  const [switchBtn, setSwitchBtn] = useState(true);

  // useEffect(() => {
  //   if (!isVisible) {
  //     setIsVisible(true);
  //   }
  // }, [isVisible]);

  useEffect(() => {
    // Simulating state change after a certain time interval
    if (!isVisible) {
      const timer = setTimeout(() => {
        setIsVisible(!isVisible);
      }, 500);

      return () => clearTimeout(timer);
    }
  }, [isVisible]);

  const { t } = useTranslation();

  // useEffect(() => {
  //   if (priceOption) {
  //     setIsVisible(false);
  //   }
  // }, [priceOption]);

  return (
    <div className="w-full flex justify-center bg-gradient-to-b from-[#FCFBFF] pb-10 lg:px-0 px-5 to-white">
      <div className="w-full relative flex flex-col items-center pt-5 lg:pt-20  pb-12 max-w-6xl">
        <h2 className="text-3xl text-text lg:text-center w-full font-bold ">
          {t("pricingTitle")}
        </h2>
        {/* //zzgl MwSt */}
        <p className="text-darkgray lg:text-center  lg:text-center text-lg  lg:mt-5 mt-3">
          {/* Die mudioo-Software kann im Jahresabo oder im Monatsabo genutzt
          werden.
          <div className="hidden lg:block" /> Außerdem kann die Software{" "}
          <span className="font-bold text-text">einen Monat kostenlos </span>{" "}
          getestet werden. */}
          {t("pricingDescriptionPt1")}
          <br /> {t("pricingDescriptionPt2")}{" "}
          <span className="font-bold text-text">
            {t("pricingDescriptionPt3")}
          </span>
          .
        </p>

        <div className="flex items-center mt-10  lg:mt-5">
          <div
            onClick={() => {
              if (!priceOption) {
                setSwitchBtn(true);
                setIsVisible(!isVisible);
                setTimeout(() => {
                  setPriceOption(true);
                }, 500);
              }
            }}
            className={`cursor-pointer ${
              switchBtn
                ? "text-primary underline font-bold"
                : "font-semibold text-text"
            }`}
          >
            {t("annualSubscription")}
          </div>
          <div
            onClick={() => {
              setSwitchBtn(!switchBtn);
              setIsVisible(!isVisible);
              setTimeout(() => {
                setPriceOption(!priceOption);
              }, 500);
            }}
            className="w-14 cursor-pointer border border-border h-8 flex items-center  rounded-full bg-lightgreen mx-5"
          >
            <div
              className={`w-6 h-6 rounded-full bg-primary ml-1 transition-all ${
                switchBtn ? "translate-x-0" : "translate-x-6"
              }`}
            />
          </div>
          <div
            onClick={() => {
              if (priceOption) {
                setSwitchBtn(false);
                setIsVisible(!isVisible);
                setTimeout(() => {
                  setPriceOption(false);
                }, 500);
              }
            }}
            className={`cursor-pointer ${
              !switchBtn
                ? "text-primary underline font-bold"
                : "text-text font-semibold"
            }`}
          >
            {t("monthlySubscription")}
          </div>
        </div>
        <div
          className={`transition-opacity duration-500 w-full ${
            isVisible ? "opacity-100" : "opacity-0"
          }`}
        >
          {priceOption ? (
            <>
              <div className="grid lg:grid-cols-3 grid-cols-1 w-full lg:gap-4 gap-2 mt-10">
                <div className="p-12 py-8 text-white w-full bg-[#161A34] text-center flex-col rounded-lg justify-center">
                  <div className="lg:h-[470px]">
                    <h1 className="text-white font-semibold text-lg ">
                      {t("basicVersion")}
                    </h1>
                    <div className="flex items-center mt-6 justify-center">
                      <div className="text-4xl font-bold">139€</div>
                      <div className="ml-1 font-semibold">{t("perYear")}</div>
                    </div>

                    <div className="text-center my-3 text-[#aaacb3] text-lg">
                      - {t("or")} -
                    </div>
                    <div>
                      <span className="font-bold">1600€</span>
                      {t("perYear")}
                    </div>

                    <div className="w-full h-0.5 my-8 bg-[#232944] w-full rounded-full" />
                    <CheckItem txt={t("includesProducts")} />
                    <CheckItem txt="CMS" />
                    <CheckItem txt="Web-App" />
                    <CheckItem txt={t("ciModularStructure")} />
                    <CheckItem txt={t("multimediaPresentation")} />
                    <CheckItem txt={t("aiContentCreationBasic")} />
                  </div>
                  <a
                    target="_blank"
                    href="https://calendly.com/christoph-v-trotha/-mudioo"
                    className="w-full font-semibold h-12 mt-8 bg-primary flex items-center justify-center rounded-lg text-white"
                  >
                    {t("freeTrial")}
                  </a>
                </div>

                {/* Erweiterung */}

                <div className="p-12 py-8 text-white w-full bg-secondary text-center flex-col rounded-lg justify-center">
                  <div className="lg:h-[470px]">
                    <h1 className="text-white font-semibold text-lg ">
                      {t("professionalVersion")}
                    </h1>
                    <div className="flex items-center mt-6 justify-center">
                      <div className="text-4xl font-bold">259€</div>
                      <div className="ml-1 font-semibold">{t("perMonth")}</div>
                    </div>

                    <div className="text-center my-3 text-lightgreen text-lg">
                      - {t("or")} -
                    </div>
                    <div>
                      <span className="font-bold">2900€</span>
                      {t("perYear")}
                    </div>

                    <div className="w-full h-0.5 my-8 bg-primary w-full rounded-full" />

                    <CheckItem txt={t("includesHunderedProducts")} />
                    <CheckItem txt={t("allBasic")} />
                    <CheckItem txt={t("aiContentCreationPro")} />
                    <CheckItem txt={t("productComp")} />
                    <CheckItem txt="Chatbot" />
                    <CheckItem txt="Live-Tracking" />
                    <CheckItem txt={t("feedbackfunction")} />
                  </div>
                  <a
                    target="_blank"
                    href="https://calendly.com/christoph-v-trotha/-mudioo"
                    className="w-full font-semibold h-12 mt-8 bg-primary flex items-center justify-center rounded-lg text-white"
                  >
                    {t("freeTrial")}
                  </a>
                </div>

                <div className="p-12 py-8 relative text-white w-full bg-secondary text-center flex-col rounded-lg justify-center">
                  <div className="lg:h-[440px]">
                    <h1 className="text-white font-semibold text-lg ">
                      Enterprise
                    </h1>
                    <div className="flex items-center mt-6 justify-center">
                      <div className="text-4xl font-bold">Let's Talk!</div>
                    </div>
                    <div className="w-full h-0.5 mt-8 mb-4 bg-primary w-full rounded-full" />

                    <div className="flex  w-full  items-center inline-flex">
                      <div className="text-4xl  text-text">∞</div>
                      <div className="ml-1 text-lg mt-1 font-semibold">
                        {t("optionsAndMore")}
                      </div>
                    </div>

                    <CheckItem txt={t("allBasic")} />
                    <CheckItem txt={t("aiContentCreationPro")} />
                    <CheckItem txt={t("productComp")} />
                    <CheckItem txt="Chatbot" />
                    <CheckItem txt="Live-Tracking" />
                    <CheckItem txt={t("feedbackfunction")} />

                    {/* <div className="flex items-center mt-6 justify-center">
                      Sie möchten mehr als 100 Produkte erstellen? Melden Sie
                      sich jetzt und wir finden ein passendes Angebot.
                    </div> */}
                  </div>

                  <a
                    target="_blank"
                    href="https://calendly.com/christoph-v-trotha/-mudioo"
                    className="w-full bottom-0 font-semibold h-12 mt-16 bg-primary flex items-center justify-center rounded-lg text-white"
                  >
                    {t("contactUs")}
                  </a>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="grid lg:grid-cols-3 grid-cols-1 w-full lg:gap-4 gap-2 mt-10">
                <div className="p-12 py-8 text-white w-full bg-[#161A34] text-center flex-col rounded-lg justify-center">
                  <div className="lg:h-[470px]">
                    <h1 className="text-white font-semibold text-lg ">
                      {t("basicVersion")}
                    </h1>
                    <div className="flex items-center mt-6 justify-center">
                      <div className="text-4xl font-bold">189€</div>
                      <div className="ml-1 font-semibold">{t("perMonth")}</div>
                    </div>

                    <div className="w-full h-0.5 my-8 bg-[#232944] w-full rounded-full" />
                    <CheckItem txt={t("includesProducts")} />
                    <CheckItem txt="CMS" />
                    <CheckItem txt="Web-App" />
                    <CheckItem txt={t("ciModularStructure")} />
                    <CheckItem txt={t("multimediaPresentation")} />
                    <CheckItem txt={t("aiContentCreationBasic")} />
                  </div>
                  <a
                    target="_blank"
                    href="https://calendly.com/christoph-v-trotha/-mudioo"
                    className="w-full font-semibold h-12 mt-8 bg-primary flex items-center justify-center rounded-lg text-white"
                  >
                    {t("freeTrial")}
                  </a>
                </div>

                {/* Erweiterung */}

                <div className="p-12 py-8 text-white w-full bg-secondary text-center flex-col rounded-lg justify-center">
                  <div className="lg:h-[470px]">
                    <h1 className="text-white font-semibold text-lg ">
                      {t("professionalVersion")}
                    </h1>
                    <div className="flex items-center mt-6 justify-center">
                      <div className="text-4xl font-bold">309€</div>
                      <div className="ml-1 font-semibold">{t("perMonth")}</div>
                    </div>

                    <div className="w-full h-0.5 my-8 bg-primary w-full rounded-full" />
                    <CheckItem txt={t("includesHunderedProducts")} />
                    <CheckItem txt={t("allBasic")} />
                    <CheckItem txt={t("aiContentCreationPro")} />
                    <CheckItem txt={t("productComp")} />
                    <CheckItem txt="Chatbot" />
                    <CheckItem txt="Live-Tracking" />
                    <CheckItem txt={t("feedbackfunction")} />
                  </div>
                  <a
                    target="_blank"
                    href="https://calendly.com/christoph-v-trotha/-mudioo"
                    className="w-full font-semibold h-12 mt-8 bg-primary flex items-center justify-center rounded-lg text-white"
                  >
                    {t("freeTrial")}
                  </a>
                </div>
                <div className="p-12 py-8 relative text-white w-full bg-secondary text-center flex-col rounded-lg justify-center">
                  <div className="lg:h-[440px]">
                    <h1 className="text-white font-semibold text-lg ">
                      Enterprise
                    </h1>
                    <div className="flex items-center mt-6 justify-center">
                      <div className="text-4xl font-bold">Let's Talk!</div>
                    </div>
                    <div className="w-full h-0.5 mt-8 mb-4 bg-primary w-full rounded-full" />

                    <div className="flex  w-full  items-center inline-flex">
                      <div className="text-4xl  text-text">∞</div>
                      <div className="ml-1 text-lg mt-1 font-semibold">
                        {t("optionsAndMore")}
                      </div>
                    </div>

                    <CheckItem txt={t("allBasic")} />
                    <CheckItem txt={t("aiContentCreationPro")} />
                    <CheckItem txt={t("productComp")} />
                    <CheckItem txt="Chatbot" />
                    <CheckItem txt="Live-Tracking" />
                    <CheckItem txt={t("feedbackfunction")} />

                    {/* <div className="flex items-center mt-6 justify-center">
                      Sie möchten mehr als 100 Produkte erstellen? Melden Sie
                      sich jetzt und wir finden ein passendes Angebot.
                    </div> */}
                  </div>

                  <a
                    target="_blank"
                    href="https://calendly.com/christoph-v-trotha/-mudioo"
                    className="w-full bottom-0 font-semibold h-12 mt-16 bg-primary flex items-center justify-center rounded-lg text-white"
                  >
                    {t("contactUs")}
                  </a>
                </div>
              </div>
            </>
          )}

          <div className="absolute text-sm bottom-3 lg:bottom-0 lg:px-0 px-5 right-0 text-darkgray">
            {t("additionalInfo")}
          </div>
        </div>
      </div>
    </div>
  );
}

const CheckItem = ({ txt }) => {
  return (
    <div className="flex items-center mt-3">
      <div className="text-white w-5">
        <CheckCircleIcon />
      </div>
      <div className="text-semibold ml-1.5">{txt}</div>
    </div>
  );
};

export default Pricing;
