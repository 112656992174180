import React from "react";
import { ArrowRightIcon } from "@heroicons/react/24/outline";

function MoreInformation() {
  return (
    <div className="lg:bg-lightgray px-6 lg:px-16 lg:pb-20 pb-20 lg:pt-20 pt-5 border-t border-border mt-10 w-full flex flex-col items-center ">
      <div className="w-full  flex-col-reverse rounded-lg flex items-center max-w-6xl relative ">
        <div className="lg:w-1/2  z-20 rounded-bl-lg rounded-br-lg lg:mt-0 mt-10  lg:p-0 p-5 flex items-center  flex-col w-full px-5 lg:px-0 lg:pr-10 ">
          <h2 className="font-bold text-center text-text text-3xl lg:mt-0 mt-3 leading-[44px]">
            Sie vertreten eine Kultureinrichtung?
          </h2>
          <p className="text-darkgray text-center  text-lg lg:mt-5 mt-3 lg:pr-5">
            Dann schauen Sie sich gerne die mudioo-Software an.
          </p>
          <a
            className="px-8 py-3 font-semibold bg-primary rounded-lg text-white mt-5 mb-5 lg:mb-0 lg:mt-10 flex items-center justify-center"
            // href="../Assets/Info.pdf"
            // download
            href={"https://mudioo.de"}
            target="_blank"
          >
            mudioo entdecken
            <ArrowRightIcon strokeWidth="3" className="w-5 ml-3" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default MoreInformation;
