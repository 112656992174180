import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { translation } from "./locale/translation";

const savedLanguage = localStorage.getItem("language") || "de"; // Gespeicherte Sprache oder Standardsprache 'en'

i18n.use(initReactI18next).init({
  resources: translation,
  lng: savedLanguage,
  fallbackLng: "en",
  debug: true,
  interpolation: {
    escapeValue: false,
  },
});

// i18n
//   .use(Backend)
//   .use(LanguageDetector)
//   .use(initReactI18next)
//   .init({
//     fallbackLng: "en",
//     debug: true,
//     interpolation: {
//       escapeValue: false, // React already safes from xss
//     },
//     backend: {
//       loadPath: "/locales/{{lng}}/{{ns}}.json", // Pfad zu den Übersetzungsdateien
//     },
//   });

export default i18n;
