import Contact from "../Components/Contact";
import Menubar from "../Components/Menubar";
import { useTranslation } from "react-i18next";

function Contactpage() {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <Menubar selected="contact" />

      <div className=" bg-gradient-to-b overflow-hidden from-lightgreen flex flex-col items-center to-white relative z-30 w-full">
        <div className=" h-28 lg:h-32"></div>
        <h1 className="font-bold z-20 text-3xl text-center lg:text-5xl max-w-4xl lg:mt-10 text-text px-5 leading-[40px]  lg:leading-[65px]">
          {t("contactNow")}
        </h1>
        <p className="text-darkgray z-20 lg:px-0 px-5 leading-[30px] lg:leading-7  text-lg max-w-3xl w-full lg:mb-0  -mb-10 mt-3  text-center">
          {t("contactNowSubtitle")}
        </p>
        <Contact type="Kontakt" />
      </div>
    </div>
  );
}

export default Contactpage;
