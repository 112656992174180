import { useTranslation } from "react-i18next";

function Supporters() {
  const { t } = useTranslation();
  // const { scrollY } = useScroll();
  // const translateLogoOne = useTransform(scrollY, [2000, 2500], [1000, 0]);
  // const translateLogoTwo = useTransform(scrollY, [2050, 2550], [1000, 0]);
  // const translateLogoThree = useTransform(scrollY, [2100, 2600], [1000, 0]);

  // const scrollInRightFirst = useTransform(scrollY, [1800, 2200], [300, 0]);
  // const scrollInRightSecond = useTransform(scrollY, [1900, 2300], [300, 0]);
  // const scrollInRightThird = useTransform(scrollY, [2000, 2340], [300, 0]);

  return (
    <div className="w-full flex bg-white border-white border-b border-t mb-12 lg:mb-32 mt-10 lg:mt-20 flex-col items-center">
      <div className="max-w-6xl ipad:px-0 px-5 flex lg:flex-row flex-col lg:items-center justify-between w-full  ">
        <h2 className="text-3xl text-text font-bold">{t("supporters")}:</h2>
        <div className="flex lg:border-0 rounded-lg mt-5 lg:mt-0 p-5 lg:p-0 border border-border lg:flex-row flex-col items-center lg:items-center w-full justify-evenly">
          <div
            // style={{ x: isMobile ? scrollInRightFirst : translateLogoOne }}
            className="w-64 lg:mt-0 mt-10 flex items-center"
          >
            <img
              alt="WTSH Logo"
              className=""
              src={require("../Assets/logo-sh.png")}
            />
          </div>
          <div
            // style={
            //   isMobile ? { x: scrollInRightSecond } : { x: translateLogoTwo }
            // }
            className="mt-10 hidden w-28 lg:mt-2 lg:flex items-center "
          >
            <img alt="ibsh" src={require("../Assets/ibsh.png")} />
          </div>
          <div
            // style={
            //   isMobile
            //     ? { x: scrollInRightThird }
            //     : {
            //         x: translateLogoThree,
            //       }
            // }
            className="w-36 lg:mt-0 mt-10 flex items-center"
          >
            <img
              alt="Kielregion logo"
              src={require("../Assets/kielregion_logo_web_RGB_auf_weiss.png")}
            />
          </div>

          <div
            // style={
            //   isMobile ? { x: scrollInRightSecond } : { x: translateLogoTwo }
            // }
            className="mt-10 w-28 lg:mt-2 lg:hidden flex items-center "
          >
            <img alt="ibsh" src={require("../Assets/ibsh.png")} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Supporters;
