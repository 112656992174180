import React, { useState, useEffect } from "react";
import { motion, useScroll, useTransform } from "framer-motion";

import Hero from "../Components/HeroV2";
import Supporters from "../Components/Supporters";
import Advantages from "../Components/Advantages";
import Faq from "../Components/Faq";
import Menubar from "../Components/Menubar";
import Contact from "../Components/Contact";
import GetBetaUser from "../Components/GetBetaUser";
import Pricing from "../Components/Pricing";
import HowToStart from "./HowToStart";
import AudioguideSample from "../Components/AudioguideSample";
import Advantages2 from "../Components/Advantages2";
import Metrics from "../Components/Metrics";
import MoreInformation from "../Components/MoreInformation";
import { InlineWidget } from "react-calendly";
import Problem from "../Components/Problem";
import HowItWorks from "../Components/HowItWorks";
import { useTranslation } from "react-i18next";

function Home({ loading }) {
  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  const { t, i18n } = useTranslation();

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  return (
    <div className="overflow-hidden">
      <Menubar selected="home" />
      <div className="lg:hidden h-10" />
      <Hero loading={loading} />
      <Problem />
      <HowItWorks />
      <Advantages />
      {/* <Targetgroup /> */}
      <Advantages2 />
      <AudioguideSample />
      <Metrics />
      {/* <Additionally /> */}
      {/* <Testamonials /> */}
      <HowToStart />
      {/* <Pricing /> */}
      <Supporters />
      {/* <Newsletter /> */}
      <GetBetaUser />
      <Faq faq={t("faq", { returnObjects: true })} />

      <div className="w-full flex justify-center">
        <div className="w-full relative flex flex-col pt-10 lg:pt-28 lg:px-0 px-5 pb-12 max-w-5xl">
          <h2 className="text-3xl shrink-0 text-dark lg:text-center font-bold">
            {t("tryRetailflow")}
          </h2>
          <p className="text-darkgray lg:text-lg relative lg:text-center mt-4 mb-10 lg:-mb-6 z-30">
            {t("infoCallDescription")}
          </p>
          <div className="border border-border lg:border-0">
            <InlineWidget url="https://calendly.com/christoph-v-trotha/-mudioo" />
          </div>
        </div>
      </div>
      {/* <MoreInformation /> */}
      <Contact type="Kontakt" bgmode={true} />
    </div>
  );
}

export default Home;
