import React, { useEffect, useState } from "react";
import "./App.css";
import Home from "./Screens/Home";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ReactComponent as RFLogo } from "./Assets/logo.svg";
import Footer from "./Components/Footer";
// import Aboutus from "./Screens/Aboutus";
import Contactpage from "./Screens/Contactpage";
import Betauser from "./Screens/Betauser";
import Imprint from "./Screens/Imprint";
import Datapolicy from "./Screens/Datapolicy";
import AppDatapolicy from "./Screens/AppDatapolicy";
import TermsOfUse from "./Screens/TermsOfUse";
import Pricingoverview from "./Screens/Pricingoverview";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    document.title = "retailflow - digitaler Vertriebsassistenten";
  }, []);

  useEffect(() => {
    const handleImageLoad = () => {
      const images = document.images;
      let loadedCount = 0;
      const totalImages = images.length;

      const checkIfAllImagesLoaded = () => {
        loadedCount++;
        if (loadedCount === totalImages) {
          setIsLoading(false);
        }
      };

      if (totalImages === 0) {
        setIsLoading(false);
      } else {
        for (let i = 0; i < totalImages; i++) {
          if (images[i].complete) {
            checkIfAllImagesLoaded();
          } else {
            images[i].addEventListener("load", checkIfAllImagesLoaded);
            images[i].addEventListener("error", checkIfAllImagesLoaded);
          }
        }
      }
    };

    handleImageLoad();
  }, []);

  return (
    <>
      {isLoading && (
        <div className="fixed w-screen h-screen z-[100] bg-white top-0 left-0 flex items-center justify-center flex-col">
          <div className="w-14 animate-pulse">
            <RFLogo />
          </div>
        </div>
      )}
      <Router>
        <Routes>
          <Route exact path="/" element={<Home loading={isLoading} />} />
          <Route path="/pricing" element={<Pricingoverview />} />
          {/* <Route path="/aboutus" element={<Aboutus />} /> */}
          <Route path="/contact" element={<Contactpage />} />
          <Route path="/getbeta" element={<Betauser />} />
          <Route path="/imprint" element={<Imprint />} />
          <Route path="/datapolicy" element={<Datapolicy />} />
          <Route path="/app_datapolicy" element={<AppDatapolicy />} />
          <Route path="/terms_of_use" element={<TermsOfUse />} />
          {/* <Route path="/app" element={<RedirectApp />} /> */}
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
