import React from "react";
import { ReactComponent as RFMockupCms } from "../Assets/cms_mockup_mobile.svg";
import { ReactComponent as RFMockupPhone } from "../Assets/phone.svg";
import { useTranslation } from "react-i18next";

import { ReactComponent as QR } from "../Assets/qr.svg";
import { motion, useScroll, useTransform } from "framer-motion";

export default function HowItWorks() {
  const slidePhoneMockups = {
    offscreen: {
      y: 150,
    },
    onscreen: {
      y: 0,

      transition: {
        type: "tween",
        bounce: 0.4,
        duration: 0.8,
      },
    },
  };

  const { t } = useTranslation();

  return (
    <div className="bg-white lg:border-t border-border justify-center lg:px-10 px-5 pb-10 lg:pt-20 pt-5 w-full relative flex ">
      <div className="max-w-6xl flex items-start w-full flex-col items-center justify-between">
        {/* <h2 className="text-3xl  py-10 w-full text-dark lg:text-center   font-bold ">
          retailfow hilft dabei,
        </h2> */}
        <h2 className="text-3xl w-full text-text lg:text-center font-bold ">
          {t("howItWorksTitle")}
        </h2>
        <div className="text-darkgray mt-4 text-lg max-w-3xl lg:text-center">
          {t("howItWorksDescription")}
        </div>
        <div className="flex mt-10   lg:pt-10">
          <div className="lg:w-2/5 w-full shrink-0 ">
            <div className="rounded-2xl lg:rounded lg:border-0 border border-border lg:overflow-auto overflow-hidden lg:bg-transparent bg-lightgray lg:p-0 p-5 text-darkgray flex flex-col items-center lg:items-start">
              <div className="bg-white px-5 my-2 py-2 rounded-full inline-flex text-text font-semibold text-sm lg:hidden mb-5">
                {t("step1")}
              </div>
              <h1 className="font-bold text-text flex items-center text-xl mb-2">
                <div className="w-8 h-8 hidden lg:flex font-bold text-base rounded-full mr-2 items-center justify-center  bg-primary text-white">
                  1
                </div>
                {t("productsCreation")}
              </h1>
              <p className="lg:pr-10 text-center lg:text-start">
                {t("step1Description")}
              </p>
              <motion.div
                initial={"offscreen"}
                whileInView={"onscreen"}
                viewport={{ once: true, amount: 0.1 }}
                variants={slidePhoneMockups}
                className="w-full lg:absolute rounded border border-border -mb-10 lg:w-0 lg:h-0 mt-10"
              >
                <RFMockupCms />
              </motion.div>
            </div>

            <div className="rounded-2xl lg:rounded lg:border-0 border border-border mt-10 lg:overflow-auto overflow-hidden lg:bg-transparent bg-lightgray lg:p-0 p-5 text-darkgray flex flex-col items-center lg:items-start">
              <div className="bg-white px-5 my-2 py-2 rounded-full inline-flex text-text font-semibold text-sm lg:hidden mb-5">
                {t("step2")}
              </div>
              <h1 className="font-bold text-text flex items-center text-xl mb-2">
                <div className="w-8 h-8 hidden lg:flex font-bold text-base rounded-full mr-2 items-center justify-center  bg-primary text-white">
                  2
                </div>
                {t("qrCodes")}
              </h1>
              <p className="lg:pr-10 text-center lg:text-start">
                {t("step2Description")}
              </p>
              <div className="w-56 h-56 bg-primary rounded-2xl p-2.5  -mb-28 lg:hidden mt-10">
                <QR />
              </div>
            </div>

            <div className="rounded-2xl lg:rounded lg:border-0 border border-border mt-10 lg:overflow-auto overflow-hidden lg:bg-transparent bg-lightgray lg:p-0 p-5 text-darkgray flex flex-col items-center lg:items-start">
              <div className="bg-white px-5 my-2 py-2 rounded-full inline-flex text-text font-semibold text-sm lg:hidden mb-5">
                {t("step3")}
              </div>
              <h1 className="font-bold text-text flex items-center text-xl mb-2">
                <div className="w-8 h-8 hidden lg:flex font-bold text-base rounded-full mr-2 items-center justify-center  bg-primary text-white">
                  3
                </div>
                {t("consulting")}
              </h1>
              <p className="lg:pr-10 text-center lg:text-start">
                {t("step3Description")}
              </p>
              <motion.div
                initial={"offscreen"}
                whileInView={"onscreen"}
                viewport={{ once: true, amount: 0.1 }}
                variants={slidePhoneMockups}
                className="w-56 rounded lg:w-0 lg:h-0 lg:absolute  -mb-56  mt-10"
              >
                <RFMockupPhone />
              </motion.div>
            </div>
          </div>
          <div className="w-3/5 ml-0 relative p-0 pl-24 bg-white rounded-lg  flex items-center justify-center">
            <div className="w-full hidden lg:block  rounded-lg shadow shadow-xl">
              <RFMockupCms />

              <motion.div
                initial={"offscreen"}
                whileInView={"onscreen"}
                viewport={{ once: true, amount: 0.1 }}
                variants={slidePhoneMockups}
                className="absolute w-28 left-12 bottom-0"
              >
                <RFMockupPhone />
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
